<div class="container">
    <mat-card class="card">
      <mat-card-title><img mat-card-image src="https://www.tdemperu.com/wp/wp-content/uploads/2017/04/LOGO-TDEM-original.jpg" alt="Photo of a Shiba Inu"></mat-card-title>
      <div class="line"></div>
      <mat-card-subtitle>	Consulta de Certificado</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="certificadoForm" novalidate class="example-form" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>N° de Certificado <span class="span-required">*</span></mat-label>
            <input matInput type="number" placeholder="N° de Certificado" formControlName="NRO_CERTIFICADO" >
          </mat-form-field>
  
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>Fecha de Certificado</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="FECHA_EJECUCION" placeholder="MM/DD/YYYY" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>
          
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>DNI Técnico / Código Seguridad <span class="span-required">*</span></mat-label>
            <input matInput type="text" placeholder="DNI Técnico / Código Seguridad" formControlName="DNI_CODIGO" >
          </mat-form-field>
          
          <div class="form-group text-center">
            <re-captcha
              siteKey="6Ldwh0oqAAAAADpf8HvUKpSo1jEjQPNzCBGBrMq4" size="300px"
              (resolved)="onCaptchaResolved($event)">
            </re-captcha>
          </div>
          <button mat-raised-button type="submit"  class="full-width button-color">CONSULTAR</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>