
<mat-toolbar class="toolbar-color">
    <img mat-card-image src="https://www.tdemperu.com/wp/wp-content/uploads/2017/04/LOGO-TDEM-original.jpg" alt="Photo of a Shiba Inu">
    <span class="title">Consulta de certificados de inspección</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon class-button" aria-label="Example icon-button with share icon" (click)="exit()">
        <mat-icon>power_settings_new</mat-icon>Salir
    </button>
</mat-toolbar>
<div class="iframe-container">
    <iframe [src]="urlPDF" width="800" height="1160"></iframe>
</div>


