import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsultaCertificadoInacalComponent } from './components/consulta-certificado-inacal/consulta-certificado-inacal.component';
import { PdfCertificadoInacalComponent } from './components/pdf-certificado-inacal/pdf-certificado-inacal.component';


const routes: Routes = [ 
  {path:"", component: ConsultaCertificadoInacalComponent},
  {path:"consulta", component: ConsultaCertificadoInacalComponent},
  {path:"certificado/:url", component: PdfCertificadoInacalComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
