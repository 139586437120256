import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CertificadoInacalApiService } from 'src/app/services/api/certificado-inacal-api.service';

@Component({
  selector: 'app-consulta-certificado-inacal',
  templateUrl: './consulta-certificado-inacal.component.html',
  styleUrls: ['./consulta-certificado-inacal.component.scss']
})
export class ConsultaCertificadoInacalComponent implements OnInit {
  title = 'consulta-certificado-inacal';
  certificadoForm: FormGroup;
  captchaValid: boolean = false;
  durationInSeconds = 5;

  constructor(private formBuilder: FormBuilder, private certificadoInacalApi: CertificadoInacalApiService, private router: Router,  private _snackBar:MatSnackBar) {}

  ngOnInit() {
    this.buildForm();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ["success-snackbar"],
      duration: 10000
    });
  }

  private buildForm(){
    this.certificadoForm = this.formBuilder.group({
      NRO_CERTIFICADO: ['', Validators.required],
      FECHA_EJECUCION: [(new Date()).toISOString(), Validators.required],
      DNI_CODIGO: ['', Validators.required],
      RECAPTCHA: ['', Validators.required]
    });
  }

  onCaptchaResolved(captchaResponse: string) {
    if (captchaResponse) {
      this.certificadoForm.patchValue({ RECAPTCHA: captchaResponse });
      this.captchaValid = true;
    } else {
      this.captchaValid = false;
    }
  }

  async onSubmit() {
    if (this.certificadoForm.valid && this.captchaValid) {
      // Envías los datos incluyendo la respuesta del captcha
      
      const fecha = new Date(this.certificadoForm.value.FECHA_EJECUCION);
      const fechaFormateada = fecha.toISOString().split('T')[0];
      const list:any = await this.certificadoInacalApi.getPromise(this.certificadoForm.value.NRO_CERTIFICADO,this.certificadoForm.value.DNI_CODIGO,fechaFormateada);
      console.log(fechaFormateada);
      console.log(list);
      if(list.length>0){
        this.router.navigate(['/certificado',list[0].URL]);
      }else{
        this.openSnackBar(list.mensaje, "");
        this.router.navigate(['/']);
      }
    } else if(this.captchaValid) {
      this.openSnackBar("Para continuar debe completar el reCAPTCHA.", "");
    }else{
      this.openSnackBar("Para continuar debe ingresar los datos solicitados.", "");
    }
  }

}