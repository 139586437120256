import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-certificado-inacal',
  templateUrl: './pdf-certificado-inacal.component.html',
  styleUrls: ['./pdf-certificado-inacal.component.scss']
})
export class PdfCertificadoInacalComponent implements OnInit {

  @Input() url: string;
  urlPDF= null;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.urlPDF = this.sanitizarURL(params.get('url'));
      console.log("Parametro", this.urlPDF);
    });
  }
  sanitizarURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  exit(){
    this.router.navigate(['/consulta'], { replaceUrl: true });
  }

}
